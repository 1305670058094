var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-card-title',[_vm._v("Lista Clientes "),_c('v-btn',{attrs:{"icon":"","color":"green","loading":_vm.cargandoDatos,"disabled":_vm.cargandoDatos},on:{"click":function($event){return _vm.cargarClientes()}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiCached))])],1)],1),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","offset-md":"8","md":"4"}},[_c('v-text-field',{attrs:{"append-icon":_vm.icons.mdiMagnify,"label":"Buscar","single-line":"","hide-details":"","dense":"","outlined":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('v-data-table',{staticClass:"table-kitchen-sink",attrs:{"headers":_vm.headers,"items":_vm.usuarioList,"search":_vm.search,"items-per-page":5,"loading":_vm.usuarioListLoad},scopedSlots:_vm._u([{key:"item.documento",fn:function(ref){
var item = ref.item;
return [_c('small',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(item.tipo_cliente_json.avb_descripcion)+" "+_vm._s(item.tipo_cliente_json.descripcion))]),_c('br'),_c('strong',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(item.documento))])]}},{key:"item.nombre",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(item.nombre)+" "+_vm._s(item.apellido))])]}},{key:"item.puesto",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('div',[_c('v-avatar',{staticClass:"v-avatar-light-bg white--text",attrs:{"size":"1.875rem","color":"primary"}},[(item.puesto_json.img)?_c('v-img',{attrs:{"src":((_vm.config.api_imagenes) + "/assets/images/puestos/" + (item.puesto_json.img))}}):(item.puesto_json.nombre)?_c('span',[_vm._v(_vm._s(item.puesto_json.nombre.slice(0, 2).toUpperCase()))]):_c('span',[_vm._v("S/N")])],1)],1)])]}},{key:"item.accion",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('a',_vm._g(_vm._b({staticClass:"pr-2",on:{"click":function($event){return _vm.GetEditarCliente(item)}}},'a',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiPencil))])],1)]}}],null,true)},[_c('span',[_vm._v("Editar")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('a',_vm._g(_vm._b({staticClass:"pr-2"},'a',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(item.estado == 5 ? _vm.icons.mdiCancel : _vm.icons.mdiCheck))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.estado == 5 ? 'Inctivar' : 'Activar'))])])]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }