
<template>
  <div>
    <v-card>
      <v-card-title
        >Lista Clientes

        <v-btn icon @click="cargarClientes()" color="green" :loading="cargandoDatos" :disabled="cargandoDatos">
          <v-icon>{{ icons.mdiCached }}</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" offset-md="8" md="4">
            <v-text-field
              v-model="search"
              :append-icon="icons.mdiMagnify"
              label="Buscar"
              single-line
              hide-details
              dense
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>

      <!-- Table -->
      <v-data-table
        :headers="headers"
        :items="usuarioList"
        :search="search"
        :items-per-page="5"
        :loading="usuarioListLoad"
        class="table-kitchen-sink"
      >
        <template #[`item.documento`]="{ item }">
          <small class="text-no-wrap"
            >{{ item.tipo_cliente_json.avb_descripcion }} {{ item.tipo_cliente_json.descripcion }}</small
          >
          <br />
          <strong class="text-no-wrap">{{ item.documento }}</strong>
        </template>
        <template #[`item.nombre`]="{ item }">
          <span class="text-no-wrap">{{ item.nombre }} {{ item.apellido }}</span>
        </template>
        <template #[`item.puesto`]="{ item }">
          <div class="d-flex align-center">
            <div>
              <v-avatar size="1.875rem" color="primary" class="v-avatar-light-bg white--text">
                <v-img
                  v-if="item.puesto_json.img"
                  :src="`${config.api_imagenes}/assets/images/puestos/${item.puesto_json.img}`"
                ></v-img>
                <span v-else-if="item.puesto_json.nombre">{{ item.puesto_json.nombre.slice(0, 2).toUpperCase() }}</span>
                <span v-else>S/N</span>
              </v-avatar>
            </div>
          </div>
        </template>
        <template #[`item.accion`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <a class="pr-2" v-bind="attrs" v-on="on" @click="GetEditarCliente(item)">
                <v-icon>{{ icons.mdiPencil }}</v-icon>
              </a>
            </template>
            <span>Editar</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <a class="pr-2" v-bind="attrs" v-on="on">
                <v-icon>{{ item.estado == 5 ? icons.mdiCancel : icons.mdiCheck }}</v-icon>
              </a>
            </template>
            <span>{{ item.estado == 5 ? 'Inctivar' : 'Activar' }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
  
<script>
import { onBeforeMount, ref, context } from '@vue/composition-api'
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiStoreEdit,
  mdiPencilOutline,
  mdiAccountCancel,
  mdiAccountEdit,
  mdiAccountCheck,
  mdiCheck,
  mdiCancel,
  mdiStoreEditOutline,
  mdiPencil,
  mdiCached,
} from '@mdi/js'
import goTo from 'vuetify/lib/services/goto'
import ClienteService from '@/api/servicios/ClientesService'
import config from '@/api/config'
import store from '@/store'
export default {
  setup(props, context) {
    const search = ref('')
    const usuarioList = ref([])
    const usuarioListLoad = ref(false)
    const cargandoDatos = ref(false)
    const headers = ref([])
    const GetEditarCliente = item => {
      console.log(item)
      context.emit('GetEditarCliente', { ...item })
      goTo(0)
    }
    onBeforeMount(async () => {
      headers.value = [
        { text: 'DOCUMENTO', value: 'documento' },
        { text: 'NOMBRE', value: 'nombre' },
        { text: 'TELEFONO', value: 'telefono' },
        { text: 'PUESTO', value: 'puesto' },
      ]
      await cargarAtributos()
      headers.value.push({ text: 'ACCION', value: 'accion', sortable: false })

      cargarClientes()
    })

    const cargarClientes = () => {
      try {
        cargandoDatos.value = true
        ClienteService.clientesListar()
          .then(response => {
            console.log(response)
            if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
              usuarioList.value = response.data.datos
            }
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            cargandoDatos.value = false
          })
      } catch (error) {
        store.commit('setAlert', {
          message: error,
          type: 'error',
          error: error,
          funcion: 'cargarClientes',
        })
        cargandoDatos.value = false
      }
    }

    const atributos = ref([])
    const cargarAtributos = async () => {
      await ClienteService.clienteAtributosListar()
        .then(response => {
          if ((response.data.mensaje = 'BUSQUEDA_EXITOSA')) {
            response.data.datos.forEach(element => {
              headers.value.push({ text: element.nombre, value: element.nombre })
            })
          }
        })
        .catch(error => {
          store.commit('setAlert', {
            message: 'ups' + error,
            type: 'error',
            error: {
                        ...error, 
                        response: error?.response?.data
                    },
            funcion: 'cargarAtributos',
          })
        })
        .finally(() => {})
    }

    return {
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiPencilOutline,
        mdiAccountCancel,
        mdiAccountEdit,
        mdiAccountCheck,
        mdiCheck,
        mdiCancel,
        mdiStoreEdit,
        mdiStoreEditOutline,
        mdiPencil,
        mdiCached,
      },
      search,
      headers,
      usuarioList,
      usuarioListLoad,
      GetEditarCliente,
      cargarClientes,
      config,
      cargandoDatos,
    }
  },
}
</script>
  
<style lang="scss" scoped>
.table-kitchen-sink ::v-deep {
  .v-data-table-header {
    white-space: nowrap;
  }
}
</style>
  