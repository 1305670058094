<template>
    <div>
        <p class="text-2xl mb-6">Clientes</p>
        <v-container>
            <v-row>
                <v-col lg="12" cols="12">
                    <cliente-nuevo @CargarListaCliente="CargarListaCliente" :ClienteDatos="cliente"></cliente-nuevo>
                </v-col>
                <v-col lg="12" cols="12">
                    <cliente-lista ref="ClienteListaRef" @GetEditarCliente="GetEditarCliente"></cliente-lista>
                </v-col>
            </v-row>

        </v-container>
    </div>
</template>
  
<script>
// eslint-disable-next-line object-curly-newline
import CropImagen from '@/components/CropImagen.vue';
import { mdiPoll, mdiLabelVariantOutline, mdiCurrencyUsd, mdiHelpCircleOutline } from '@mdi/js'

// demos 
import { onBeforeMount, ref } from '@vue/composition-api';
import ClienteLista from './components/ClienteLista.vue';
import ClienteNuevo from './components/ClienteNuevo.vue';
import config from '@/api/config';
export default
    {
        components: { CropImagen, ClienteLista, ClienteNuevo },
        setup() {
            const cliente = ref({
                apellido: "",
                direccion: "",
                documento: "",
                id: 0,
                id_puesto: undefined,
                id_tipo_cliente: 3,
                nombre: "",
                telefono: "",
                id_grupo_tipo_cliente: 1,
                id_puesto: null
            })
            const ClienteListaRef = ref(null)

            const CargarListaCliente = () => {

                ClienteListaRef.value.cargarClientes()
            }
            const GetEditarCliente = data => {

                console.log(data)
                cliente.value = JSON.parse(JSON.stringify(
                    {
                        ...data,
                        id_grupo_tipo_cliente: data.tipo_cliente_json.id_grupo_tipo_cliente,
                    }
                    
                    ))
                
                /*{
                    id: data.id,
                    documento: data.documento,
                    nombre: data.nombre,
                    apellido: data.apellido,
                    direccion: data.direccion,
                    encargado: data.encargado,
                    id_puesto: data.id_puesto,
                    id_tipo_cliente: data.id_tipo_cliente,
                    id_grupo_tipo_cliente: data.tipo_cliente_json.id_grupo_tipo_cliente,
                    telefono: data.telefono,
                }*/
            }

            return {
                GetEditarCliente,
                CargarListaCliente,
                cliente,
                ClienteListaRef
            }
        },
    }
</script>
  