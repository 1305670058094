<template>
  <v-card flat class="pa-3 mt-2">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-card-text class="d-flex">
        <v-row>
          <v-col lg="6" md="6" cols="12">
            <v-row>
              <v-col cols="12" md="4">
                <v-radio-group v-model="datos.id_grupo_tipo_cliente" @change="selectGrupo()">
                  <v-radio
                    v-for="grupo in grupoTipoCliente"
                    :key="grupo.id"
                    :label="grupo.descripcion"
                    :value="grupo.id"
                  ></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="8">
                <v-select
                  v-model="datos.id_tipo_cliente"
                  :items="tipoPuestos"
                  item-text="descripcion"
                  item-value="id"
                  :rules="reglas.tipo_cliente"
                  label="Tipo *"
                  required
                  hide-details="auto"
                  class="mb-6 mt-5"
                  outlined
                  dense
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col :cols="datos.id_grupo_tipo_cliente == 2 ? 12 : 10">
                <v-text-field
                  :rules="reglas.documento"
                  v-model="datos.documento"
                  :label="datos.id_grupo_tipo_cliente == 1 ? 'Cedula *' : 'Documento *'"
                  required
                  outlined
                  dense
                  hide-details="auto"
                  class="mb-6"
                ></v-text-field>
              </v-col>
              <v-col cols="2" v-if="datos.id_grupo_tipo_cliente == 1">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      icon
                      color="primary"
                      @click="buscarCne()"
                      :loading="cargandoCNE"
                      :disabled="cargandoCNE || datos.documento.length < 6"
                    >
                      <v-icon>{{ icons.mdiCloudSearch }}</v-icon>
                    </v-btn>
                  </template>
                  <span>Buscar CNE</span>
                </v-tooltip>
              </v-col>
            </v-row>

            <v-text-field
              :rules="reglas.nombre"
              v-model="datos.nombre"
              :label="datos.id_grupo_tipo_cliente == 1 ? 'Nombre *' : 'Razón social *'"
              required
              outlined
              dense
              hide-details="auto"
              class="mb-6"
            ></v-text-field>

            <v-text-field
              :rules="reglas.apellido"
              v-model="datos.apellido"
              :label="datos.id_grupo_tipo_cliente == 1 ? 'Apellido *' : 'Razón Comercial *'"
              required
              outlined
              dense
              hide-details="auto"
              class="mb-6"
            ></v-text-field>
          </v-col>

          <v-col lg="6" md="6" cols="12">
            <v-text-field-simplemask
              class="mt-5"
              v-model="datos.telefono"
              v-bind:label="'Telefono'"
              v-bind:properties="{
                outlined: true,
                clearable: false,
                dense: true,
                rules: reglas.telefono,
              }"
              v-bind:options="{
                inputMask: '(####) ### ## ##',
                outputMask: '###########',
                empty: null,
                applyAfter: false,
                alphanumeric: false,
                lowerCase: true,
              }"
            />

            <v-textarea v-model="datos.direccion" label="Direccion" outlined name="input-7-4"></v-textarea>

            <v-select
              v-model="datos.id_puesto"
              :items="puestos"
              item-text="nombre"
              item-value="id"
              label="Locatario/Area"
              required
              hide-details="auto"
              class="mb-6"
              outlined
              dense
            ></v-select>
          </v-col>

          <v-col cols="12" md="6" v-for="(item, index) in atributos" :key="index">
            <TipoValor
              v-if="item.id_tipo_atributo == 1"
              :label="item.nombre"
              v-model="datos[item.nombre]"
              :descripcion="''"
            />
            <TipoSelect
              v-else-if="item.id_tipo_atributo == 2"
              :label="item.nombre"
              v-model="datos[item.nombre]"
              :descripcion="''"
              :opciones="item.agrupacion"
            />
            <TipoBoolean
              v-else-if="item.id_tipo_atributo == 3"
              :label="item.nombre"
              v-model="datos[item.nombre]"
              :descripcion="''"
            />
            <TipoHora
              v-else-if="item.id_tipo_atributo == 6"
              :label="item.nombre"
              v-model="datos[item.nombre]"
              :descripcion="''"
            />
          </v-col>
          <v-col cols="12">
            <v-btn v-if="datos.id > 0" color="secondary" class="me-3" @click="resetear()"> Resetear </v-btn>
            <v-btn color="secondary" class="me-3" @click="limpiar()"> Limpiar </v-btn>
            <v-btn color="primary" class="me-3" @click="GuardarCliente()"> Guardar </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-form>
  </v-card>
</template>
<script>
import { onBeforeMount, ref, watch } from '@vue/composition-api'
import { mdiAlertOutline, mdiCloudUploadOutline, mdiCamera, mdiCloudSearch } from '@mdi/js'
import SeguridadService from '@/api/servicios/SeguridadService'
import CropImagen from '@/components/CropImagen.vue'
import ClientesService from '@/api/servicios/ClientesService'
import store from '@/store'
import config from '@/api/config'
import PuestoService from '@/api/servicios/PuestoService'
import TipoBoolean from '../../configuracion/componentes/TipoBoolean.vue'
import TipoHora from '../../configuracion/componentes/TipoHora.vue'
import TipoSelect from '../../configuracion/componentes/TipoSelect.vue'
import TipoValor from '../../configuracion/componentes/TipoValor.vue'
export default {
  components: {
    CropImagen,
    TipoBoolean,
    TipoHora,
    TipoSelect,
    TipoValor,
  },
  props: {
    ClienteDatos: Object,
  },
  setup(props, context) {
    const valid = ref(true)
    const datos = ref(JSON.parse(JSON.stringify(props.ClienteDatos)))
    const grupoTipoCliente = ref([])
    const puestos = ref([])
    const form = ref()
    const tipoPuestos = ref([])
    watch(props, () => {
      resetear()
      selectGrupo()
    })

    onBeforeMount(() => {
      grupoTipoClienteListar()
      obtenerPuestos()
      cargarAtributos()
    })
    const atributos = ref([])
    const cargarAtributos = () => {
      ClientesService.clienteAtributosListar()
        .then(response => {
          if ((response.data.mensaje = 'BUSQUEDA_EXITOSA')) {
            atributos.value = response.data.datos
          }
        })
        .catch(error => {
          store.commit('setAlert', {
            message: 'ups' + error,
            type: 'error',
            error: {
                        ...error, 
                        response: error?.response?.data
                    },
            funcion: 'cargarAtributos',
          })
        })
        .finally(() => {})
    }

    const reglas = {
      requerido: [v => !!v || 'Es requerido'],
      documento: [
        v => !!v || 'descripcion es requerido',
        v =>
          datos.value.id_grupo_tipo_cliente == 1
            ? /^(\d{6,9})$/.test(v) || 'La cedula debe ser valida: ej 12365450'
            : true,
        v =>
          datos.value.id_grupo_tipo_cliente == 2
            ? /^([VGEJvgej][\-]\d{5,9}[\-]\d{1})$/.test(v) || 'El Rif debe ser valido: ej J-12345678-0'
            : true,
      ],
      id: -1,

      nombre: [
        v => !!v || 'Nombre es requerido',
        v => (v && v.length <= 50 && v.length >= 3) || 'Nombre debe ser valido',
      ],
      apellido: [
        v => !!v || 'Apellido es requerido',
        v => (v && v.length <= 50 && v.length >= 3) || 'Apellido debe ser valido',
      ],
      comision: [v => !!v || 'Participacion es requerido'],
      telefono: [
        v =>
          !!v
            ? /(\(0414|0412|0416|0426|0424)?[ -]*([0-9][ -]*){3}([0-9][ -]*){2}[ -]*([0-9][ -]*){2}$/.test(v) ||
              'Participacion Invalida, Ej: 15 o 12.5'
            : true,
      ],
      tipo_cliente: [v => !!v || 'Tipo cliente es requerido'],
    }

    const validarForm = () => {
      let val = form.value?.validate()

      return val
    }
    const grupoTipoClienteListar = () => {
      ClientesService.grupoTipoClienteListar()
        .then(response => {
          if ((response.data.mensaje = 'BUSQUEDA_EXITOSA')) {
            grupoTipoCliente.value = response.data.datos
            selectGrupo()
          }
        })
        .catch(error => {
          store.commit('setAlert', {
            message: 'ups' + error,
            type: 'error',
            error: {
                        ...error, 
                        response: error?.response?.data
                    },
            funcion: 'grupoTipoClienteListar',
          })
        })
        .finally(() => {})
    }
    const cargandoCNE = ref(false)

    const buscarCne = () => {
      cargandoCNE.value = true
      const dat = { ci: datos.value.documento }

      ClientesService.listarClienteCNE(dat)
        .then(response => {
          if (response.data.mensaje == 'OK') {
            ;(datos.value.nombre = response.data.data.nombre), (datos.value.apellido = response.data.data.apellido)
            datos.value.direccion = `${response.data.data.Parroquia} ${response.data.data.Municipio}  ${response.data.data.Estado}`
          }
          console.log(response.data)
        })
        .catch(error => {
          store.commit('setAlert', {
            message: 'ups' + error,
            type: 'error',

            error: {
                        ...error, 
                        response: error?.response?.data
                    },
            funcion: 'buscarCne',
          })
        })
        .finally(() => {
          cargandoCNE.value = false
        })
    }
    const obtenerPuestos = () => {
      PuestoService.obtenerPuestos()
        .then(response => {
          if ((response.data.mensaje = 'BUSQUEDA_EXITOSA')) {
            puestos.value = response.data.datos
          }
        })
        .catch(error => {
          store.commit('setAlert', {
            message: 'ups' + error,
            type: 'error',
            error: {
                        ...error, 
                        response: error?.response?.data
                    },
            funcion: 'obtenerPuestos',
          })
        })
        .finally(() => {})
    }
    const resetear = () => {
      datos.value = JSON.parse(JSON.stringify(props.ClienteDatos))

      form.value.resetValidation()
    }

    const selectGrupo = () => {
      tipoPuestos.value = grupoTipoCliente.value.find(
        item => item.id == datos.value.id_grupo_tipo_cliente,
      )?.tipo_cliente_json
      datos.value.id_tipo_cliente = datos.value.id_grupo_tipo_cliente == 1 ? 3 : 7
    }

    const limpiar = () => {
      datos.value = {
        apellido: '',
        direccion: '',
        documento: '',
        id: 0,
        id_puesto: undefined,
        id_tipo_cliente: 3,
        nombre: '',
        telefono: '',
        id_grupo_tipo_cliente: 1,
        id_puesto: null,
      }
      form.value.resetValidation()
    }

    const GuardarCliente = async () => {
      try {
        if (validarForm()) {
          try {
            ClientesService.nuevoCliente(datos.value)
              .then(response => {
                if (response.data.mensaje == 'CLIENTE GUARDADO CON EXITO') {
                  console.log(response.data)
                  limpiar()
                  context.emit('CargarListaCliente')
                  store.commit('setAlert', {
                    message: response.data.mensaje,
                    type: 'success',
                  })
                } else {
                  console.log(response.data)
                  store.commit('setAlert', {
                    message: 'UPS, ' + response.data.mensaje,
                    type: 'info',
                  })
                }
              })
              .catch(error => {
                store.commit('setAlert', {
                  message: 'ups' + error,
                  type: 'error',
                  error: {
                        ...error, 
                        response: error?.response?.data
                    },
                  funcion: 'GuardarCliente',
                })
              })
              .finally(() => {})
          } catch (error) {
            store.commit('setAlert', {
              message: 'upss.' + error,
              type: 'error',
              error: error,
              funcion: 'GuardarCliente',
            })
          }
        } else {
          store.commit('setAlert', {
            message: 'Verifique que todos los datos estén completos',
            type: 'warning',
          })
        }
      } catch (error) {
        store.commit('setAlert', {
          message: error,
          type: 'error',

          error: error,
          funcion: 'GuardarCliente',
        })
      }
    }

    return {
      icons: {
        mdiCloudSearch,
      },
      valid,
      datos,
      reglas,
      grupoTipoCliente,
      GuardarCliente,
      form,
      puestos,
      limpiar,
      config,
      resetear,
      tipoPuestos,
      selectGrupo,
      buscarCne,
      cargandoCNE,
      atributos,
      cargarAtributos,
    }
  },
}
</script>